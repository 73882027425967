import React, { Component } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";
import classNames from "classnames";
import Icon from "./icon";

// Assets.
import "./sass/slick.scss";
import TestimonialStyles from "./css-modules/testimonials.module.scss";

export default class Testimonials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total: 0,
      counter: 1
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    const { edges: items } = this.props.items;
    this.setState({
      total: items.length
    });
  }

  setCount(index) {
    this.setState({
      counter: index
    });
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    if (!this.props.items) {
      return;
    }

    const { edges: items } = this.props.items;
    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: index => {
        this.setCount(index + 1);
      }
    };

    return (
      <div className={TestimonialStyles.testimonials}>
        <div className={TestimonialStyles.actions}>
          <button
            type="button"
            onClick={this.previous}
            className={classNames(
              TestimonialStyles.action,
              TestimonialStyles.previous
            )}
          >
            <Icon name="chevron-right" />
            <span>Vorige</span>
          </button>
          <span className={TestimonialStyles.countLabel}>Referentie</span>
          <span className={TestimonialStyles.count}>
            {this.state.counter}
          </span>{" "}
          / <span className={TestimonialStyles.count}>{this.state.total}</span>
          <button
            type="button"
            onClick={this.next}
            className={classNames(
              TestimonialStyles.action,
              TestimonialStyles.next
            )}
          >
            <span>Volgende</span>
            <Icon name="chevron-right" />
          </button>
        </div>

        <Slider ref={c => (this.slider = c)} {...settings}>
          {items.map(({ node: data }) => {
            const { name, company, logo } = data.frontmatter;

            console.log(logo);

            return (
              <blockquote
                key={data.id}
                className={TestimonialStyles.testimonial}
              >
                <div dangerouslySetInnerHTML={{ __html: data.html }} />

                <cite className={TestimonialStyles.citation}>
                  <span>{name}</span> - {company}
                </cite>

                {logo ? <Img fixed={logo.childImageSharp.fixed} /> : ""}
              </blockquote>
            );
          })}
        </Slider>
      </div>
    );
  }
}
